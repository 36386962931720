export const MAX_UPLOAD_FILE_SIZE = 4000000; // 4 MB
export const ROUTE_CONSTANTS = {
  ROOT: '/',
  REFERRALS: '/referrals',
  FAQS: '/faqs',
  LOGIN: '/login',
  PROFILE: {
    MAIN: '/profile',
    DETAIL: '/profile:id',
  },
  ERROR: '/*',
};

// export const statusFilters = [
//   { value: 'All', label: 'All' },
//   { value: 'Screening', label: 'Screening' },
//   { value: 'Interview', label: 'Interview' },
//   { value: 'Shortlisted', label: 'Shortlisted' },
//   { value: 'Not Selected', label: 'Not Selected' },
//   { value: 'On-Hold', label: 'On-Hold' },
//   { value: 'Offered', label: 'Offered' },
//   { value: 'Hired', label: 'Hired' },
//   { value: 'Backed out', label: 'Backed out' },
//   { value: 'Not Interested', label: 'Not Interested' },
//   { value: 'Not Responding', label: 'Not Responding' },
//   { value: 'Duplicate', label: 'Duplicate' },
// ];

export const zohoRedirectUrl =
  'https://recruitsandbox.zoho.com/recruit/org711263075/EntityInfo.do?module=Candidates&submodule=Candidates&id=';


export const TOAST_MESSAGES = {
  SOMETHING_WENT_WRONG: 'Something Went Wrong!',
  SERVER_ERROR: 'Server error !',
  RESUME_ERROR: 'Error while previewing resume!',
  UPLOAD_RESUME_ERROR: 'Please upload pdf or docx file',
  LINKEDIN_SUCCESS: 'Job shared on linkedin.',
  TWITTER_SUCCESS: 'Job shared on twitter.',
  JOB_SHARING_ERROR: 'Job was not shared.',
  UPLOADING_RESUME_ERROR: 'Error while uploading Resume!',
  MAX_SIZE_RESUME_UPLOAD_ERROR: `Max ${MAX_UPLOAD_FILE_SIZE / 1000000} MB size resume is allowed to upload`,
  REPUSH_CANDIDATE: 'Candidate Repushed!',
  MULTIPLE_REPUSH: 'Candidates Repushed!',
  PUSH_CANDIDATE: 'Candidate Pushed!',
  MULTIPLE_PUSH: 'Candidates Pushed!',
  REPUSH_FAILED: 'Repush Failed!',
  UNASSOCIATE_TO_JOB: 'Successfully Unassociated!',
  RESUME_PUSHED_TO_ZOHO: 'Resume Successfully Pushed to Zoho!'
};

export const DOMAIN = '.qa2.tothenew.net';

export const REFERRAL_POLICY_URL = 'https://docs.google.com/document/d/1hSzwofdtjyT6wUv4yxRAuvRYkwesgNEV-8YNAsUo868/edit#heading=h.1jlao46';